import _createPlugin2 from "../util/createPlugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createPlugin = _interopRequireDefault(_createPlugin2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = _createPlugin.default;
exports.default = _default;
export default exports;